import marketsConfig from "config/markets.json"

const marketsOrder: string[] = [
  "FR",
  "DE",
  "ES",
  "GP",
  "IE",
  "IT",
  "LU",
  "MC",
  "MQ",
  "NL",
  "PT",
  "RO",
  "BE",
  "CH",
  "UK",
  "GB",
]

export function getAllZones(): string[] {
  return Object.entries(marketsConfig)
    .reduce<string[]>((acc, [_, countries]) => {
      acc.push(...countries)
      return acc
    }, [])
    .sort((a, b) => {
      return marketsOrder.indexOf(a) - marketsOrder.indexOf(b)
    })
}
