import getCurrency from "~/lib/get-currency"
import { getAllZones } from "~/lib/i18n/utils/get-all-zones"
import { getCountryDisplayName } from "~/lib/i18n/utils/get-display-name"
import { getIntl, getLang, getLocaleByCountry } from "~/lib/i18n/utils/get-i18n"
import { getCountryEmojiFromCountryCode } from "~/components/ui/LangSwitcher"
import { getCurrencyDetails } from "~/utils/get-currency-details"

export function getAllZonesOptions() {
  const zones = getAllZones()

  return zones.filter(Boolean).flatMap((country) => {
    const locale = getLocaleByCountry(country)
    if (!locale) return []

    const intl = getIntl(locale)
    const regionCode = intl.split("-")[1]
    const lang = getLang(locale)
    const countryDisplay = getCountryDisplayName(regionCode, lang)
    const currency = getCurrency(locale)
    const currencyDetails = getCurrencyDetails(currency)

    if (!regionCode || !countryDisplay) return []

    const EULabel = `${getCountryEmojiFromCountryCode(country)} ${getCountryDisplayName(country, lang)} | ${
      currencyDetails.symbol
    }`
    const otherCountryLabel = `${getCountryEmojiFromCountryCode(regionCode)} ${countryDisplay} | ${
      currencyDetails.symbol
    }`
    const label = countryDisplay === "European Union" ? EULabel : otherCountryLabel

    // Return only if label is successfully constructed
    if (!label) {
      return []
    }

    return {
      label,
      value: country,
    }
  })
}
